export default function LandingPage() {
  return (
    <main className="container">
      <section className="py-8 xl:py-16">
        <h1 className="text-center text-4xl font-bold">
          <span className="text-primary">IZONE</span> Study
        </h1>
      </section>
    </main>
  );
}
